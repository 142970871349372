<template>
    <admin-dashboard-layout>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col v-text="user.name"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.users.index'}" v-text="'return'"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Name"
                                v-model="user.name"
                                :disabled="loading"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Email"
                                v-model="user.email"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Password"
                                v-model="user.password"
                                :type="user.id ? 'password' : 'text'"
                                :disabled="loading"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Confirm password"
                                v-model="user.password_confirmation"
                                :type="user.id ? 'password' : 'text'"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>
                    <v-select
                        label="Roles"
                        v-model="user.roles"
                        :items="roles"
                        :disabled="loading"
                        multiple
                        class="mb-4"
                    />
                    <v-row justify="space-between">
                        <v-col>
                            <v-btn @click="save" v-text="'Save'"/>
                        </v-col>
                        <v-col class="text-right">
                            <delete-dialog
                                v-if="$route.params.id"
                                :model="user"
                                type="users"
                                @success="deleted"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import User from "@/models/User";
import Roles from "@/api/Roles";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import { mapMutations } from "vuex";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
    name: 'user-form',
    components: {
        AdminDashboardLayout,
        DeleteDialog
    },
    data() {
        return {
            user: {},
            loading: true,
            roles: [],
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async fetch() {
            this.loading = true;

            try {
                this.roles = await Roles.list()

                if (this.$route.params.id) {
                    this.user = await User.find(this.$route.params.id);
                    this.user.roles = this.user.roles.map(role => role.name);
                } else {
                    const password = this.generatePassword();

                    this.user.password = password;
                    this.user.password_confirmation = password;
                }
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
            } finally {
                this.loading = false;
            }
        },

        async save() {
            this.loading = true;

            try {
                const entity = new User(this.user);
                this.user = await entity.save();
                this.user.roles = this.user.roles.map(role => role.name);
                this.showSnackBar({color: 'success', timeout: 3000, text: 'User saved'});
                await this.$router.push({name: 'admin.users.index'});
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'});
            } finally {
                this.loading = false;
            }
        },

        generatePassword() {
            return Math.random().toString(36).slice(2);
        },

        deleted() {
            this.$router.push({name: 'admin.users.index'})
        }
    }
}
</script>
